/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var date = new Date();

        if (localStorage.getItem('location') === null || localStorage.getItem('expire') < date) {
          var expireDate = new Date();
          localStorage.setItem('expire', expireDate.setDate(expireDate.getDate() + 1));
          $.getJSON("https://ipinfo.io",
            function(json) {
              if (json.country === 'CA' || json.country === 'US') {
                localStorage.setItem('location', 'us');
                $('.contact-us').show();
              } else {
                localStorage.setItem('location', 'nl');
                $('.contact-nl').show();
              }
            }
          );
        } else {
          if (localStorage.getItem('location') === 'us') {
            $('.contact-us').show();
          } else {
            $('.contact-nl').show();
          }
        }

        // JavaScript to be fired on all pages
        $('.block.slider .slider').owlCarousel({
          loop:true,
          margin:0,
          nav:false,
          dots:true,
          mouseDrag: true,
          touchDrag: true,
          items:1,
          thumbs: false,
          thumbsPrerendered: false,
          autoplay:true,
          autoplayTimeout:6000,
          autoplayHoverPause:true,
          // merge:true,
          // video:true,
          // lazyLoad:true,
          // center:true,
        });

        $('.block .slider').owlCarousel({
          loop:true,
          margin:0,
          nav:false,
          dots:true,
          mouseDrag: true,
          touchDrag: true,
          items:1,
          thumbs:true,
          thumbsPrerendered: true,
          autoplay:true,
          autoplayTimeout:6000,
          autoplayHoverPause:true,
          responsiveClass:true
        });

        $('.block .dynamic .carousel').owlCarousel({
          loop: false,
          margin: 0,
          nav: false,
          dots: true,
          mouseDrag: true,
          touchDrag: true,
          items: 4,
          thumbs: true,
          thumbsPrerendered: true,
          responsiveClass:true,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:2
            },
            1000:{
              items:4
            }
          }
        });

        $('header#newHeader li.search a').click(function(){
          if ($('.search-bar').hasClass('active')) {
            $('.search-bar').removeClass('active');
          } else {
            $('.search-bar').addClass('active');
          }
          return false;
        });

        document.getElementById("menu-trigger").onclick = function() {
          if (document.getElementById("menu-trigger").classList.contains('active')) {
            document.getElementById("menu-trigger").classList.remove('active');
            document.getElementById("newHeader").classList.remove('active');
            document.getElementById("nav-primary").classList.remove('active');
          } else {
            document.getElementById("menu-trigger").classList.add('active');
            document.getElementById("newHeader").classList.add('active');
            document.getElementById("nav-primary").classList.add('active');
          }
        };

        if ($(window).width() < 768) {
          $('#newHeader .menu-item-has-children').click(function () {
            if ($(this).hasClass('active')) {
              $(this).removeClass('active');
            } else {
              $(this).addClass('active');
              return false;
            }
          });

          $('#newHeader  li.menu-item-has-children > a').each(function (index, elem) {
            //$('#mainmenu li &gt; ul').each(function (index, elem) {
            /* Option 1: Use this to modify the href on the &lt;a&gt; to # */
            $(elem).prev('a').attr('href' ,'#');

            /* OR Option 2: Use this to keep the href on the &lt;a&gt; intact but prevent the default action */
            $(elem).prev('a').click(function(event) {
              event.preventDefault();
            });
          });
        }

        var current = '',
          total_slides = '';

        $('.block.gallery a.gallery-popup').click(function() {
          $('#gallery-overlay .gallery-item').removeClass('active');
          $('#gallery-overlay').addClass('active');
          current = $(this).data('slide');
          total_slides = $(this).data('slide-total');
          $('#gallery-overlay').find("[data-slide='" + current + "']").addClass('active');
        });

        $('#gallery-close').click(function() {
          $('#gallery-overlay, #gallery-overlay .gallery-item').removeClass('active');
        });

        $('#gallery-overlay .gallery-next').click(function() {
          $('#gallery-overlay .gallery-item').removeClass('active');

          if (current === total_slides) {
            current = 0;
          } else {
            current = current+1;
          }
          $('#gallery-overlay').find("[data-slide='" + current + "']").addClass('active');
        });

        $('#gallery-overlay .gallery-prev').click(function() {
          $('#gallery-overlay .gallery-item').removeClass('active');
          if (current === 0) {
            current = total_slides;
          } else {
            current = current-1;
          }
          $('#gallery-overlay').find("[data-slide='" + current + "']").addClass('active');
        });

        var lastScrollTop = 80;
        // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
        window.addEventListener("scroll", function(){
          var st = window.pageYOffset || document.documentElement.scrollTop;
          if (st > 80) {
            // downscroll code
            $('.top-button').addClass('active');
          } else {
            // upscroll code
            $('.top-button').removeClass('active');
          }
        }, false);

        $('a[href*="#"]')
        // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  };
                });
              }
            }
          });

        $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  };
                });
              }
            }
          });
      /*
        var header = document.getElementById('header');
        header.addEventListener("mouseover", function(){
          //if (!header.addlassList.contains('active')) {
            header.classList.add('show');
          //}
        }, false);

        var lastScrollTop = 80;
        // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
        window.addEventListener("scroll", function(){
          var st = window.pageYOffset || document.documentElement.scrollTop;
          var header = document.getElementById('header');
          if (!header.classList.contains('active')) {
            if (st > lastScrollTop && st > 80) {
              // downscroll code
              header.classList.add('offtop');
              header.classList.remove('show');
            } else if (st === 0) {
              header.classList.remove('offtop');
              header.classList.remove('show');
            } else {
              // upscroll code
              header.classList.add('show');
            }
          }
          lastScrollTop = st;
        }, false);
      */
        // Gallery
        function galleryOpen(slide) {
          var wzgOverlay = document.getElementById('gallery-overlay');
          wzgOverlay.classList.add('active');
        }
        function galleryClose() {
          var wzgOverlay = document.getElementById('gallery-overlay');
          wzgOverlay.classList.remove('active');
        }

        window.onkeydown = function(event) {
          if (event.keyCode === 27) {
            var wzgOverlay = document.getElementById('gallery-overlay');
            wzgOverlay.classList.remove('active');
          }
        };
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
